<script>
import { mapState, mapActions } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'
import PaginationCustom from '@/components/table/Pagination.vue'
import mixinHelper from '@/mixins/helper'

export default {
   name: 'Role',
   components: {
      BCardCode,
      VueGoodTable,
      PaginationCustom,
   },
   mixins: [mixinHelper],
   data() {
      return {
         item: {},
         mode: 'INSERT',
         form: {
            nama: null,
            deskripsi: null,
            ResourceItems: [],
         },
         required,
         pageLength: 10,
         pageOptions: ['10', '25', '50', '100'],
         searchTerm: '',
         columns: [
            {
               label: 'Nama',
               field: 'nama',
               sortable: false,
               thClass: 'text-center align-middle',
               filterOptions: {
                  enabled: true,
                  placeholder: 'Cari',
               },
            },
            {
               label: 'Deskripsi',
               field: 'deskripsi',
               sortable: false,
               thClass: 'text-center align-middle',
               filterOptions: {
                  enabled: true,
                  placeholder: 'Cari',
               },
            },
            {
               label: 'Action',
               field: 'action',
               sortable: false,
               thClass: 'text-center align-middle',
            },
         ],
         options: {
            status: [
               { value: null, text: '-' },
               { value: true, text: 'Aktif' },
               { value: false, text: 'Tidak Aktif' },
            ],
         },
      }
   },
   computed: {
      ...mapState({
         myData: state => state.Role.items,
         myResources: state => state.Role.resources,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
      statusUser() {
         const data = {
            true: 'Aktif',
            false: 'Tidak Aktif',
         }
         return status => (data[status] ? data[status] : status)
      },
   },
   mounted() {
      this.loadItems()
   },
   methods: {
      ...mapActions({
         getData: 'Role/getData',
         getResources: 'Role/getResources',
         getItem: 'Role/getItem',
         postItem: 'Role/postItem',
         updateItem: 'Role/updateItem',
         deleteItem: 'Role/deleteItem',
      }),
      onAdd() {
         this.getResources().then(() => {
            this.resetForm()
            this.form.ResourceItems = this.myResources
            this.$bvModal.show('modal-form')
         })
      },
      onEdit(id) {
         this.getItem(id).then(item => {
            this.form = item
            this.mode = 'UPDATE'
            this.$bvModal.show('modal-form')
         })
      },
      onDelete(id) {
         this.$swal({
            title: 'Apakah anda yakin?',
            text: 'Data tidak dapat dipulihkan kembali!',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: 'Ya, Hapus!',
            html: false,
            allowOutsideClick: false,
            customClass: {
               confirmButton: 'btn btn-danger',
               cancelButton: 'btn btn-outline-dark ml-1',
            },
            buttonsStyling: false,
            preConfirm: () => this.deleteItem(id).then(res => {
               this.loadItems()
               this.$swal('Deleted!', res.message, 'success')
            }).catch(() => {
               this.$swal('Gagal!', '', 'error')
            }),
         })
      },
      onSave() {
         this.$refs.myForm.validate().then(success => {
            console.log(success)
            if (success) {
               if (this.mode === 'INSERT') {
                  this.postItem(this.form).then(res => {
                     this.loadItems()
                     this.$bvModal.hide('modal-form')
                     this.$toast.success(res.message)
                  })
               } else if (this.mode === 'UPDATE') {
                  this.updateItem(this.form).then(res => {
                     this.loadItems()
                     this.$bvModal.hide('modal-form')
                     this.$toast.success(res.message)
                  })
               }
            }
         })
      },
      resetForm() {
         this.form = {
            nama: null,
            deskripsi: null,
            ResourceItems: [],
         }
         this.mode = 'INSERT'
      },
      onDetail(id) {
         this.item = this.myData.find(item => item.id === id)
         this.$bvModal.show('modal-detail')
      },
      onFilter() {
         setTimeout(() => {
            this.loadItems()
         }, 100)
      },
      onRefresh() {
         setTimeout(() => {
            this.loadItems()
         }, 100)
      },
      // VUE GOOD TABLE
      async loadItems() {
         await this.getData(this.filter)
      },
   },
}
</script>

<template>
   <div>
      <b-card-code title="Data Role">
         <!-- search input -->
         <b-row>
            <!-- TOOLBAR -->
            <b-col cols="12" md="12">
               <div class="d-flex justify-content-end">
                  <b-button-group class="mb-1 align-self-end">
                     <b-button
                        v-b-tooltip.hover.top="'Tambah'"
                        variant="outline-secondary"
                        size="sm"
                        @click="onAdd()"
                     >
                        <feather-icon icon="PlusIcon" />
                     </b-button>
                     <b-button
                        v-b-tooltip.hover.top="'Refresh'"
                        variant="outline-secondary"
                        size="sm"
                        @click="onRefresh()"
                     >
                        <b-spinner v-if="isLoading" small />
                        <feather-icon v-if="!isLoading" icon="RefreshCwIcon" />
                     </b-button>
                     <b-dropdown variant="outline-secondary" right text="Menu" size="sm">
                        <b-dropdown-item @click="onAdd()">
                           Tambah
                        </b-dropdown-item>
                        <b-dropdown-item @click="onRefresh()">
                           Refresh
                        </b-dropdown-item>
                     </b-dropdown>
                  </b-button-group>
               </div>
            </b-col>
            <!-- FILTER -->
         </b-row>

         <!-- table -->
         <vue-good-table
            ref="goodTable"
            :columns="columns"
            :rows="myData"
            :line-numbers="true"
            :pagination-options="{
               enabled: true,
               mode: 'pages',
            }"
         >
            <template slot="table-row" slot-scope="props">
               <span v-if="props.column.field == 'nama'">
                  <b>{{ props.row.nama }}</b>
               </span>
               <span v-if="props.column.field == 'deskripsi'">
                  <b>{{ props.row.deskripsi }}</b>
               </span>

               <span v-if="props.column.field == 'action'">
                  <center>
                     <b-button-group>
                        <b-button
                           v-b-tooltip.hover.top="'Detail'"
                           variant="outline-dark"
                           size="sm"
                           @click="onDetail(props.row.id)"
                        >
                           <feather-icon icon="EyeIcon" />
                        </b-button>
                        <b-button
                           v-b-tooltip.hover.top="'Edit'"
                           variant="outline-dark"
                           size="sm"
                           @click="onEdit(props.row.id)"
                        >
                           <feather-icon icon="EditIcon" />
                        </b-button>
                        <b-button
                           v-b-tooltip.hover.top="'Delete'"
                           variant="outline-danger"
                           size="sm"
                           @click="onDelete(props.row.id)"
                        >
                           <feather-icon icon="TrashIcon" />
                        </b-button>
                     </b-button-group>
                  </center>
               </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
               <pagination-custom
                  :total="props.total"
                  :page-length="pageLength"
                  :page-options="pageOptions"
                  :page-changed="props.pageChanged"
                  :per-page-changed="props.perPageChanged"
               />
            </template>
         </vue-good-table>
      </b-card-code>

      <b-modal
         id="modal-form"
         size="lg"
         no-close-on-backdrop
         scrollable
         title="Form Role"
      >
         <div style="height: 70vh;">
            <validation-observer ref="myForm">
               <b-form>
                  <b-form-group label="Nama" label-for="nama">
                     <validation-provider #default="{ errors }" name="Nama" rules="required">
                        <b-form-input
                           v-model="form.nama"
                           :state="errors.length > 0 ? false : true"
                           type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>
                  <b-form-group label="Deskripsi" label-for="deskripsi">
                     <validation-provider #default="{ errors }" name="Deskripsi" rules="required">
                        <b-form-input
                           v-model="form.deskripsi"
                           :state="errors.length > 0 ? false : true"
                           type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>
                  <table class="table">
                     <tr>
                        <td>No</td>
                        <td>Resource</td>
                        <td>Status</td>
                        <td>Create</td>
                        <td>Update</td>
                        <td>Delete</td>
                     </tr>
                     <tr v-for="(resource, index) in form.ResourceItems" :key="resource.id">
                        <td>{{ (index + 1) }}</td>
                        <td>{{ resource.resource_uid }}</td>
                        <td>
                           <b-form-checkbox
                              v-model="resource.status"
                              name="check-button"
                              switch
                              inline
                           >{{ resource.status }}</b-form-checkbox>
                        </td>
                        <td>
                           <b-form-checkbox
                              v-model="resource._create"
                              name="check-button"
                              switch
                              :disabled="!resource.status"
                           />
                        </td>
                        <td>
                           <b-form-checkbox
                              v-model="resource._update"
                              name="check-button"
                              switch
                              :disabled="!resource.status"
                           />
                        </td>
                        <td>
                           <b-form-checkbox
                              v-model="resource._delete"
                              name="check-button"
                              switch
                              :disabled="!resource.status"
                           />
                        </td>
                     </tr>
                  </table>
               </b-form>
            </validation-observer>
         </div>
         <template #modal-footer>
            <div class="w-100">
               <b-button
                  v-b-tooltip.hover.top="'Simpan'"
                  variant="primary"
                  type="button"
                  class="float-right"
                  @click.prevent="onSave"
               >
                  <b-spinner v-if="isLoading" small />Simpan
               </b-button>
            </div>
         </template>
      </b-modal>

      <b-modal
         id="modal-detail"
         scrollable
         size="lg"
         hide-footer
         title="Detail"
      >
         <b-list-group>
            <b-list-group-item>
               <b>Nama</b>
               : {{ item.nama }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Deskripsi</b>
               : {{ item.deskripsi }}
            </b-list-group-item>
         </b-list-group>
      </b-modal>
   </div>
</template>

<style scoped>
table > tbody > tr > td > span > hr {
   margin-top: 0rem;
   margin-bottom: 0rem;
}

.nowrap {
   white-space: nowrap;
}
</style>
